import { graphql } from 'gatsby'
import LocalizedLink from './LocalizedLink'
import AssetLink from './AssetLink'
import ExternalLink from './ExternalLink'
import UrlSlug from './UrlSlug'

const links = {
  Link: LocalizedLink,
  Url: ExternalLink,
  AssetLink,
  UrlSlug,
  AppLink: LocalizedLink,
}

const EntryLink = ({ __typename, ...props }) => {
  if (!__typename) {
    return 'error unknown typename'
  }
  if (__typename.indexOf('contentful_') === -1) {
    __typename = `contentful_${__typename}`
  }
  const C = links[__typename.split('_')[1]]

  if (C) {
    return <C {...props} />
  }
  return __typename.split('_')[1]
}

export const ItemLinkFragment = graphql`
  fragment ItemLink on contentful_Entry {
    __typename
    sys {
      id
    }
    ...AssetLink
    ...Link
    ...Url
    ...UrlSlug
    ...AppLink
  }
`

export default EntryLink
